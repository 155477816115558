<template>
  <div class="archived-list-filter">
    <div class="card filter-form collapse" id="list-filter">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-5 col-md-4 col-xl-4 px-xl-2">
            <div class="form-group">
              <label class="text-muted">Название проекта</label>
              <div class="input-group">
                <input v-model="filter.name"
                       type="text"
                       class="form-control"
                       autocomplete="off">
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-2 px-xl-2">
            <div class="form-group">
              <label class="text-muted">Тип проекта</label>
              <div class="input-group">
                <select class="form-control"
                        autocomplete="off"
                        @change="handleSelectType"
                >
                  <option></option>
                  <option :value="ProjectType.LOAN">Займ</option>
                  <option :value="ProjectType.PROJECT">Проект</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-2 px-xl-2">
            <div class="form-group">
              <label class="text-muted">Сортировка</label>
              <div class="input-group">
                <select class="form-control"
                        autocomplete="off"
                        @change="handleSelectSorting"
                >
                  <option></option>
<!--                  <option value="interest_rate">Ставка</option>-->
                  <option value="created_at">Дата создания</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-2 px-xl-2">
            <div class="form-group">
              <label class="text-muted">Порядок</label>
              <div class="input-group">
                <select class="form-control"
                        autocomplete="off"
                        @change="handleSelectOrder"
                >
                  <option></option>
                  <option value="DESC">По убыванию</option>
                  <option value="ASC">По возрастанию</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-xl-12 px-xl-2 ">
            <label class="d-none d-md-block d-xl-none">&nbsp;</label>
            <button type="submit" class="btn btn-outline-success">
              Фильтровать
            </button>
            <button @click="resetFilter" type="submit" class="btn btn-outline-info  ml-1">
              Сбросить фильтр
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {InputMoney} from "@/components/common";
import {ProjectType} from "@/utils/api-client/enums/ProjectType";

export default {
  name: "ArchivedListFilter",
  computed: {
    ProjectType() {
      return ProjectType
    }
  },
  components: {
    InputMoney,
  },
  props: {
    filter: Object
  },
  mounted() {
    this.resetFilter();
  },
  methods: {
    resetFilter() {
      Object.assign(this.filter, {
        name: null,
        projectType: null,
        sorting: null,
        order: null
      });
    },
    handleSelectSorting(e) {
      this.filter.sorting = e.target.value;
    },
    handleSelectType(e) {
      this.filter.projectType = e.target.value;
    },
    handleSelectOrder(e) {
      this.filter.order = e.target.value;
    }
  }
}
</script>
